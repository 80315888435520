#parentdiv {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
}
#firstchilddiv {
  min-height: 230px;
  background: #efefef;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}
#secondchilddiv {
  background: #efefef;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
#beacongridstyle {
  margin-left: 2.5%;
  margin-top: 2%;
  margin-right: 3%;
  margin-bottom: 2%;
}
#beaconpaperstyle {
  width: 90%;
  height: 180px;
}
#locationgridstyle {
  margin-left: -4%;
  margin-top: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
}
#locationpaperstyle {
  width: 90%;
  height: 180px;
}
#campaigngridstyle {
  margin-left: -3%;
  margin-top: 2%;
  margin-right: -1%;
  margin-bottom: 2%;
}
#campaignpaperstyle {
  width: 90%;
  height: 180px;
}
#notificationsgridstyle {
  margin-left: 2.5%;
  margin-top: 1%;
  margin-right: -5%;
  margin-bottom: 0%;
}
#notificationspaperstyle {
  height: 95%;
  width: 75%;
}
#mapgridstyle {
  margin-left: -5%;
  margin-top: 1%;
  margin-right: -1%;
  margin-bottom: 0%;
}
#mapgridstyleIndoor {
  margin-left: 2%;
  margin-top: 1%;
  margin-right: 11%;
  margin-bottom: 0%;
}
#mappaperstyle {
  height: 95%;
  width: 110%;
}

/* Beacon display */
#beaconstypographystyle {
  font-size: 25px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  padding: 8px 8px 8px 20px;
}
#beaconsnumbertypographystyle {
  font-size: 41px;
  font-weight: light;
  color: #29abe2;
  padding: 8px 8px 8px 20px;
  font-family: "helvetica neue", sans-serif;
}
#seemoreinfostyle {
  font-size: 19px;
  font-weight: bolder;
  padding: 8px 8px 3px 20px;
  color: #29abe2;
  font-family: "helvetica neue", sans-serif;
  cursor: pointer;
}
/* locations display */
#locationstypographystyle {
  font-size: 25px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  padding: 8px 8px 8px 20px;
}
#locationstypographystyleofsublocation {
  font-size: 16px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  padding: 10px 8px 15px 20px;
}
#locationsnumbertypographystyle {
  font-size: 41px;
  font-weight: light;
  color: #29abe2;
  padding: 8px 8px 8px 20px;
  font-family: "helvetica neue", sans-serif;
}

#locationsnumbertypographystyleofsublocation {
  font-size: 41px;
  font-weight: light;
  color: #29abe2;
  padding: 8px 8px 12px 20px;
  font-family: "helvetica neue", sans-serif;
}

#locationseemoreinfostyle {
  font-size: 19px;
  font-weight: bolder;
  padding: 8px 8px 3px 20px;
  color: #29abe2;
  font-family: "helvetica neue", sans-serif;
  cursor: pointer;
}
/* campaign display */
#campaignstypographystyle {
  font-size: 25px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  padding: 8px 8px 8px 20px;
}
#campaignsnumbertypographystyle {
  font-size: 41px;
  font-weight: light;
  color: #29abe2;
  padding: 8px 8px 8px 20px;
  font-family: "helvetica neue", sans-serif;
}
#campaignsseemoreinfostyle {
  font-size: 19px;
  font-weight: bolder;
  padding: 8px 8px 3px 20px;
  color: #29abe2;
  font-family: "helvetica neue", sans-serif;
  cursor: pointer;
}
/* notifications display */
#notificationstypographytitle {
  font-size: 27px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  padding: 5px 5px 5px 5px;
}
#avatarstyle {
  margin-left: 75%;
  font-size: 28px;
  width: 48px;
  height: 48px;
  background-color: #29abe2;
  margin-top: -20px;
}
#notificationpaperstyle {
  background-color: #efefef;
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 3%;
}
#notificationimgstyle {
  height: 60%;
  width: 60%;
  margin-top: 20%;
  margin-left: 10%;
}
#notificationimgstyle {
  height: 60%;
  width: 60%;
  margin-top: 20%;
  margin-left: 10%;
}
#notificationtypographystyle {
  font-size: 18px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
.notificationinfo {
  font-size: 17px;
  font-family: "helvetica neue", sans-serif;
  margin: 0px;
}
/* notifications display */
#mapgrid {
  width: 98%;
  height: 98%;
  margin-left: 1%;
  margin-top: 0%;
  padding-top: 1%;
}
