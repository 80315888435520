#beaconname {
  flex:1;
  padding: 1% 0% 0.5% 2%;
  font-size: 20px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
#statusstyle {
  background-color: #bfe6f7;
  font-size: 12px;
  font-weight: bold;
  margin:auto 5px;
  line-height: 1;
  padding:5px 10px;
  text-align: center;
  font-family: "helvetica neue", sans-serif;
}
#infostyle {
  flex:1;
  margin:10px;
}
#gridstyle {
  padding: 5px 10px 0px 10px;
}

#field {
  font-size: 15px;
  color: #a3a3a3;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 2%;
}
#deviceinfo {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 5%;
}
#iduid {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 60px;
}
#url {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 60px;
}
#uidtelemetry {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 33px;
}
#instanceuid {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 7px;
}

#protcolsinfo {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 6%;
}
#txtelemetry {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 5px;
}
#majortelemetry {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 30px;
}
#locationinfo {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 5.6%;
}
#minortelemetry {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 30px;
}
#sublocationinfo {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 3.3%;
}
#maxrangetelemetry {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 33px;
}
#firmwareinfo {
  font-size: 15px;
  /* font-weight: bolder; */
  font-family: "helvetica neue", sans-serif;
  padding-left: 33px;
}
#settingstyle {
  width: 40%;
  height: 40%;
  background-color: #4c75e7ce;
  padding: 10px 10px 10px 10px;
  margin-top: 20px;
  border-radius: 50%;
}
#beaconsettingstitle {
  padding: 1% 0% 0.5% 2%;
  /* background-color: #bfe6f7; */
  font-size: 20px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
#line {
  text-decoration: underline;
}
#title {
  padding: 1% 0% 0% 2%;
  /* background-color: #bfe6f7; */
  font-size: 20px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
