#adduserstyle {
  font-size: 22px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#addusercontentstyle {
  font-size: 18px;
  color: #a3a3a3;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#divstyle {
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: center;
}
#circulardiv {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20;
}
.headerstyle {
  font-size: 20px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#bodystyle {
  font-size: 16px;
  padding: 20px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#addressstyle {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: normal;
  width: 500px;
  font-family: "helvetica neue", sans-serif;
}
