#addlocation {
  font-size: 22px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#addlocationdescription {
  font-size: 18px;
  color: #a3a3a3;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#location {
  margin-left: 2%;
  padding-top: 1%;
}
.checkboxtype {
  font-size: 15px;
  /* color: #a3a3a3; */
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#subscriptiontypetext {
  font-size: 18px;
  color: #a3a3a3;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  /* margin-left: 2%; */
  padding-top: 1%;
  margin-right: 1%;
}
#subscriptioncheckboxes {
  margin-left: 2%;
}
#addlocationbutton {
  flex: 1;
  margin: 10px;
  color: white;
  font-size: 18px;
}
#circulardiv {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20;
}
.headerstyle {
  font-size: 20px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}

#bodystyle {
  font-size: 16px;
  padding: 20px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#addressstyle {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: normal;
  width: 500px;
  font-family: "helvetica neue", sans-serif;
}
.title {
  font-size: 20px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
  margin-bottom: 0%;
}

.content {
  font-size: 20px;
  font-weight: bolder;
  color: #a3a3a3;
  font-family: "helvetica neue", sans-serif;
  margin-top: 0%;
}

.errormessage {
  color: red;
  font-size: 10px;
}
#deleteicon {
  color: #173964;
  height: 60%;
  width: 60%;
  cursor: pointer;
}
#editicon {
  color: #a3a3a3;
  height: 60%;
  width: 60%;
  cursor: pointer;
}
