#parentdiv {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 900px;
}
#firstchilddiv {
  height: 160px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

#secondchilddiv {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
#titlegrid {
  margin-left: 2%;
  margin-right: 3%;
  margin-top: -4%;
}
#campaigngrid {
  margin-left: 2%;
  margin-right: 3%;
  margin-top: 2%;
  width: 28%;
  /* border: 3px solid green; */
}
#cardcontent {
  display: flex;
  justify-content: center;
  height: 17vh;
}
#titledescriptiondiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
#campaigntitle {
  font-size: 22px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
#campaigndescription {
  font-size: 18px;
  color: #a3a3a3;
  font-family: "helvetica neue", sans-serif;
}
#spanstyle {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
#addcampaigngrid {
  margin-left: 2%;
  margin-right: 3%;
  margin-top: 2%;
  margin-bottom: 20%;
  width: 28%;
}
#timetabletypography {
  font-size: 22px;
  font-weight: bolder;
  margin-top: 15px;
  font-family: "helvetica neue", sans-serif;
}
#start {
  font-size: 14px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}

#end {
  font-size: 14px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
#daybutton {
  width: 100px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: "helvetica neue", sans-serif;
}
#prioritystyle {
  margin-top: 20px;
  min-width: 100px;
}
#addimage {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#gridparent {
  margin-top: 10px;
  margin-bottom: 0px;
}
#paperstyle {
  margin-left: 5%;
  width: 230%;
}
#locationtypography {
  margin-left: 1.5%;
  padding-top: 1%;
}
#locationspan_1 {
  font-size: 22px;
  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
#locationspan_2 {
  font-size: 18px;
  color: #a3a3a3;

  font-weight: bolder;
  font-family: "helvetica neue", sans-serif;
}
